<template>
  <div class="UserList" >

    <!-- ERROR MODAL -->
    <b-modal ref="globalAPILoadingErrorModal" id="globalAPILoadingErrorModal"  hide-footer title="Une erreur s'est produite">
      <div class="text-center">
        <p>Une erreur s'est produite, veuillez actualiser la page et réessayer</p>
        <b-button class="mt-3" variant="outline-dark" @click="$bvModal.hide('globalAPILoadingErrorModal')">Fermer</b-button>
      </div>
    </b-modal>

    <!-- MODIFY USER -->
    <b-modal ref="modifyCollaborateurModal" size="lg" hide-footer>
      <template v-slot:modal-title>
        <strong> Modifier ce collaborateur </strong>
      </template>
      <div>
        <b-form v-on:submit.prevent="modifyCollaborateur()">
          <b-form-group id="modify-collaborateur-username-group" label="Email" label-for="modify-collaborateur-username-input" class="mandatory-input">
            <b-form-input
              v-model="modifyCollaborateurUsername" type="email"
              maxlength="149"
              placeholder="Email"
              id="modify-collaborateur-username-input"
              @input="$v.modifyCollaborateurUsername.$touch()"
              :state="$v.modifyCollaborateurUsername.$dirty ? !$v.modifyCollaborateurUsername.$error : null">
            </b-form-input>
            <small class="text-info"> Il s'agira de l'identifiant de connexion</small><br>
            <div class="form-error" v-if="!$v.modifyCollaborateurUsername.email">Veuillez renseigner un format d'email valide.</div>
          </b-form-group>

          <div class="row">
            <div class="col-md-4">
              <b-form-group id="modify-collaborateur-sex-group"
                label="Genre"
                label-for="modify-collaborateur-sex-input"
                class="mandatory-input">
                <b-form-select
                  v-model="modifyCollaborateurSex"
                  id="modify-collaborateur-sex-input"
                  @input="$v.modifyCollaborateurSex.$touch()"
                  :state="$v.modifyCollaborateurSex.$dirty ? !$v.modifyCollaborateurSex.$error : null"
                  :options='genderOptions'>
                </b-form-select>
              </b-form-group>
            </div>

            <div class="col-md-4">
              <b-form-group id="modify-collaborateur-first_name-group" label="Prénom" label-for="modify-collaborateur-first_name-input" class="mandatory-input">
                <b-form-input
                  v-model="modifyCollaborateurFirstName" type="text"
                  maxlength="29"
                  placeholder="Prénom"
                  id="modify-collaborateur-first_name-input"
                  @input="$v.modifyCollaborateurFirstName.$touch()"
                  :state="$v.modifyCollaborateurFirstName.$dirty ? !$v.modifyCollaborateurFirstName.$error : null">
                </b-form-input>
                <div class="form-error" v-if="$v.modifyCollaborateurFirstName.$error">Votre prénom doit comporter entre 2 et 30 caractères</div>
              </b-form-group>
            </div>
            <div class="col-md-4">
              <b-form-group id="modify-collaborateur-last_name-group" label="Nom" label-for="modify-collaborateur-last_name-input" class="mandatory-input">
                <b-form-input
                  v-model="modifyCollaborateurLastName" type="text"
                  maxlength="149"
                  placeholder="Nom"
                  id="modify-collaborateur-last_name-input"
                  @input="$v.modifyCollaborateurLastName.$touch()"
                  :state="$v.modifyCollaborateurLastName.$dirty ? !$v.modifyCollaborateurLastName.$error : null">
                </b-form-input>
                <div class="form-error" v-if="$v.modifyCollaborateurLastName.$error">Votre nom doit comporter entre 2 et 150 caractères</div>
              </b-form-group>
            </div>
          </div>

          <b-button
            pill block type="submit" variant="outline-dark" class="mt-2"
            :disabled="isInvalidModifyCollaborateur">
               Modifier votre collaborateur
          </b-button>
        </b-form>
      </div>
    </b-modal>

    <b-modal ref="CollaborateurUpdatedModal" id="CollaborateurUpdatedModal" size="lg" hide-footer title="Collaborateur modifié">
      <div class="text-center">
        <p><strong>Le profil de votre collaborateur a bien été mis à jour</strong></p>
        <b-button class="mt-3" variant="outline-dark" @click="$bvModal.hide('CollaborateurUpdatedModal')">Fermer</b-button>
      </div>
    </b-modal>

    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      color="#F9BF3B">
    </loading>

    <formTitleComponent />
      <div class="container">
        <h1 class="form_div_title text-center">Paramétrage des comptes</h1>
        <div class="form_div">
          <div class="form_content">
            <div class="row">
              <div class="align-self-center col-12 col-sm-6">
                <strong> Liste des utilisateurs de Decidcrawl</strong>
              </div>
              <div class="align-self-center col-12 col-sm-6 text-right">
                <b-button variant="outline-success" pill @click="$router.push('/users/creation')">
                  <v-icon name="plus"/> Ajouter un utilisateur
                </b-button>
              </div>
            </div>
            <b-table
              responsive class="align-middle text-center mt-2"
              headVariant="light"
              :fields="usersRenderFields"
              :items="users"
              >
              <template v-slot:cell(actions)="data">
                <b-button
                  variant="outline-primary" class="ml-2" size="sm"
                  @click='showModifyCollaborateurModal(data.item)'
                  v-b-popover.hover.top="'Modifier'">
                  <v-icon name="edit"/>
                </b-button>
                <!--<b-button
                  variant="outline-danger" class="ml-2" size="sm"
                  @click='showDeleteAccordDocumentModal(data.item.id)'
                  v-b-popover.hover.top="'Supprimer'">
                  <v-icon name="trash-alt"/>
                </b-button>-->
              </template>
            </b-table>

          </div>
        </div>
    </div>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength, email} from 'vuelidate/lib/validators'

import { APIUser } from '@/api/APIUser'
import formTitleComponent from '@/components/formTitleComponent'

const apiUser = new APIUser()

export default {
  name: 'UserList',
  components: {
    formTitleComponent,
    Loading
  },
  mixins: [
    validationMixin
  ],
  data: function () {
    return {
      users: [],
      usersRenderFields: [
        { key: "user.first_name", label: "Prénom", tdClass: 'ui-helper-center', sortable: true},
        { key: "user.last_name", label: "Nom", tdClass: 'ui-helper-center', sortable: true},
        { key: "user.username", label: "Identifiant", tdClass: 'ui-helper-center', sortable: true},
        { key: "actions", label: "", tdClass: 'minWidthTd ui-helper-center'},
      ],
      isUsersLoading: true,

      // ----- MODIFY USER ---------
      modifyCollaborateurInProcess: false,
      modifyCollaborateurId: '',
      modifyCollaborateurUsername: '',
      modifyCollaborateurFirstName: '',
      modifyCollaborateurLastName: '',
      modifyCollaborateurSex: 'H',

      genderOptions: [
        { text: 'Un homme', value: 'H' },
        { text: 'Une femme', value:  'F' },
      ],
    }
  },
  computed: {
    token () {
      return this.$store.state.auth.token
    },
    isLoading () {
      if (this.isUsersLoading || this.modifyCollaborateurInProcess) {
        return true
      }
      return false
    },
    isInvalidModifyCollaborateur () {
      if (this.$v.modifyCollaborateurFirstName.$invalid || this.$v.modifyCollaborateurLastName.$invalid || this.$v.modifyCollaborateurUsername.$invalid) {
        return true
      }
      return false
    },
  },
  validations: {
    modifyCollaborateurSex: {
      required
    },
    modifyCollaborateurFirstName: {
      minLength: minLength(2),
      maxLength: maxLength(30),
      required
    },
    modifyCollaborateurLastName: {
      minLength: minLength(2),
      maxLength: maxLength(150),
      required
    },
    modifyCollaborateurUsername: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(150),
      email
    },
  },
  created: function() {
    this.getAllCollaborateurs()
  },
  methods: {
    // ------------- GETTERS ------------------
    getAllCollaborateurs () {
      this.isUsersLoading = true
      apiUser.getAllCollaborateurs(this.token)
      .then((result) => {
        this.users = result.data
      })
      .catch((error) => {
        this.$refs.globalAPILoadingErrorModal.show()
        throw new Error('UserList getAllCollaborateurs API Error : ' + String(error))
      })
      .finally(() => {
        this.isUsersLoading = false
      })
    },

    // ----------- MODIFY COLLABORATEUR -----
    showModifyCollaborateurModal (collaborateur) {
      this.modifyCollaborateurId = collaborateur.id
      this.modifyCollaborateurUsername = collaborateur.user.username
      this.modifyCollaborateurFirstName = collaborateur.user.first_name
      this.modifyCollaborateurLastName = collaborateur.user.last_name
      this.modifyCollaborateurSex = collaborateur.sex

      this.$refs.modifyCollaborateurModal.show()
    },

    modifyCollaborateur () {
      this.modifyCollaborateurInProcess = true
      apiUser.modifyCollaborateurProfil(
        this.token, this.modifyCollaborateurId, this.modifyCollaborateurUsername,
        this.modifyCollaborateurSex, this.modifyCollaborateurFirstName,
        this.modifyCollaborateurLastName)
      .then(() => {
        this.$refs.modifyCollaborateurModal.hide()
        this.$refs.CollaborateurUpdatedModal.show()
        this.getAllCollaborateurs()
      })
      .catch((error) => {
        this.$refs.globalAPILoadingErrorModal.show()
        throw new Error('UserList modifyCollaborateur API Error : ' + String(error))
      })
      .finally(() => {
        this.modifyCollaborateurInProcess = false
      })

    },
  }
}
</script>
